<template>
  <div>
    <div class="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image mb-3" data-bs-bg="../../../public/assets/img/pageTitle/kurumsal.png">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="ltn__breadcrumb-inner">
              <h1 class="page-title">{{ langcontent.urunler }}</h1>
              <div class="ltn__breadcrumb-list">
                <ul>
                  <li><router-link to="anasayfa" ><span class="ltn__secondary-color"><i class="fas fa-home"></i></span> {{ langcontent.anasayfa }}</router-link></li>
                  <li> {{ langcontent.urunler }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ltn__category-area ltn__product-gutter section-bg-1--- pb-70">
      <div class="container">
        <div class="row ltn__category-slider-active--- slick-arrow-1 justify-content-center rowEQ">
          <div v-for="item in categories" class="col-lg-3 col-md-4 col-sm-6 col-6 EQcol">

            <div class="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
              <router-link :to="'/kategori/'+item.seo" class="aa">
                <div>
                  <img :src="item.img" alt="">
                </div>
                <span class="category-title">{{ item.heading }}</span>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bg-overlay-white-30:before{
  background-image: url("../../../public/assets/img/pageTitle/pg.png");
  background-position-x: center;
}
</style>
<script>
import iso from "../../axios";
export default {
  props:['langcontent'],
  data() {
    return {
      categories:[]
    }
  },
  created() {
    iso.get('categories')
        .then(response => {
          this.categories = response.data
        })
        .catch(error => {
          console.log(error);
        })
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
}
</script>
